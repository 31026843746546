import React from 'react';
import Button from "../../../../src/components/Button";
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
export default {
  React,
  Button,
  AnchorLink,
  getImage,
  GatsbyImage
};