import React from 'react';

const sizes = {
  default: `py-3 px-8`,
  sm: `py-4 px-3`,
  lg: `py-4 px-12`,
  xl: `py-5 px-16 text-lg`,
};

const Button = ({
  children,
  className = '',
  size,
  onClick = null,
  btnBackground = 'bg-red-dedalog',
  type = 'button',
  isDisabled = false,
}) => {
  return (
    <button
      disabled={isDisabled}
      type={type}
      onClick={onClick}
      className={`
        ${sizes[size] || sizes.default}
        ${btnBackground}
        hover:bg-red-dedalog-dark
        rounded
        text-white
        font-bold
        ${className}
    `}
    >
      {children}
    </button>
  );
};

export default Button;
